<template>
  <div>
    <div class="a">123</div>
  </div>
</template>

<script>
export default {

}
</script>

<style>
.a{
    font-size: 6vw;
}
</style>